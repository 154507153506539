// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-detailed-pricing-tsx": () => import("./../../../src/pages/detailed_pricing.tsx" /* webpackChunkName: "component---src-pages-detailed-pricing-tsx" */),
  "component---src-pages-entrusting-agreement-pl-tsx": () => import("./../../../src/pages/entrusting-agreement_pl.tsx" /* webpackChunkName: "component---src-pages-entrusting-agreement-pl-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-pl-tsx": () => import("./../../../src/pages/privacy_policy_pl.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-pl-tsx" */),
  "component---src-pages-service-closed-tsx": () => import("./../../../src/pages/service-closed.tsx" /* webpackChunkName: "component---src-pages-service-closed-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */)
}

