export default {
  primary: "#34393B",
  secondary: "#FA3926",
  yellow: "#FFCF63",
  background: "#FDFAFA",
  gray: "#6B6B6B",
  white: "#FFFFFF",
  gradient: "linear-gradient(147.38deg, #F05545 17.99%, #FE311C 77.01%)",
  topBarBackground: "#52466C",
  lightRed: "#FFCACA",
  card: "#F05545",
  codeBlockRed: "#FA5341",
  codeBlockGrey: "99908F",
}
