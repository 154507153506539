import merge from "lodash/merge"

import colors from "./colors"

const breakpoints = [
  ["phone_small", 320],
  ["phone", 376],
  ["phablet", 540],
  ["tablet", 735],
  ["desktop", 1070],
  ["desktop_medium", 1280],
  ["desktop_large", 1440],
]

const fonts = {
  numberFont: `'Headland One', serif`,
  exo: `'Exo', sans-serif`,
  sansSerif: `'Open Sans', sans-serif`,
}

const radius = {
  normal: "12px",
}

const shadow = {
  box: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
}

const transition = {
  button: "0.3s ease-in",
}

export default merge({
  initialColorMode: "light",
  useCustomProperties: true,
  colors,
  fonts,
  shadow,
  breakpoints,
  radius,
  transition,
})
